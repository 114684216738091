import React from 'react';
import { Menu } from 'lucide-react';
import LanguageDropdown from './LanguageDropdown';
import ProfileDropdown from './ProfileDropdown';
import ThemeToggle from './ThemeToggle';
import { useTheme } from '../context/ThemeContext';

const Topbar = ({ setSidebarOpen }) => {
  const { darkMode } = useTheme();

  return (
    <header className={`flex justify-between items-center h-16 px-4 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} border-b ${darkMode ? 'border-gray-700' : 'border-gray-200'}`}>
      <div className="flex items-center gap-2">
        <button
          onClick={() => setSidebarOpen((prev) => !prev)}
          className={`focus:outline-none lg:hidden ${darkMode ? 'text-white' : 'text-gray-500'}`}
        >
          <Menu className="h-6 w-6" />
        </button>
        <a href="/" className="lg:hidden">
          <img
            src={darkMode ? "/logo-dark.svg" : "/logo-light.svg"}
            alt="Logo"
            className="h-11 w-auto"
          />
        </a>
      </div>
      <div className="flex items-center gap-4">
        <LanguageDropdown />
        <ThemeToggle />
        <ProfileDropdown />
      </div>
    </header>
  );
};

export default Topbar;
import React from 'react';
import { useTheme } from '../context/ThemeContext';

function Billing() {
  const { darkMode } = useTheme();

  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-md shadow-md`}>
      <h2 className="text-2xl font-semibold mb-4">Billing</h2>
      <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>Manage your billing and payments here.</p>
      <div className="mt-6 space-y-4">
        <div className={`p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg`}>
          <h3 className="text-lg font-medium mb-2">Current Plan</h3>
          <p className="text-xl font-bold">Professional</p>
          <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>$49/month</p>
        </div>
      </div>
    </div>
  );
}

export default Billing;
import React from 'react';
import { useTheme } from '../context/ThemeContext';

function Analytics() {
  const { darkMode } = useTheme();

  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-md shadow-md`}>
      <h2 className="text-3xl font-semibold mb-4">Analytics</h2>
      <p className="text-gray-600 dark:text-gray-300">Welcome to the Analytics page. Here you will find various metrics and insights about your usage and performance.</p>
      <div className="mt-6">
        <h2 className="text-xl font-semibold">Usage Overview</h2>
        <p className="text-gray-600 dark:text-gray-300">This section provides an overview of your resource usage, including data transfer, requests, and caching efficiency.</p>
      </div>
    </div>
  );
}

export default Analytics;
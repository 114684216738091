import React from 'react';
import { useTheme } from '../context/ThemeContext';

function OpenTicket() {
  const { darkMode } = useTheme();

  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-md shadow-md`}>
      <h2 className="text-2xl font-semibold mb-4">Open a New Ticket</h2>
      <form className="mt-6 space-y-4">
        <div>
          <label className="block text-sm font-medium mb-2">Subject</label>
          <input
            type="text"
            className={`w-full px-4 py-2 rounded-md ${
              darkMode ? 'bg-gray-700 text-white' : 'bg-white border-gray-300'
            }`}
            placeholder="Enter ticket subject"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2">Description</label>
          <textarea
            className={`w-full px-4 py-2 rounded-md ${
              darkMode ? 'bg-gray-700 text-white' : 'bg-white border-gray-300'
            }`}
            rows="4"
            placeholder="Describe your issue"
          ></textarea>
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Submit Ticket
        </button>
      </form>
    </div>
  );
}

export default OpenTicket;
import React from 'react';
import { useTheme } from '../context/ThemeContext';

function AccountSettings() {
  const { darkMode } = useTheme();

  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-md shadow-md`}>
      <h2 className="text-2xl font-semibold mb-4">Account Settings</h2>
      <div className="mt-6 space-y-6">
        <div>
          <h3 className="text-lg font-medium mb-4">Profile Information</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-2">Name</label>
              <input
                type="text"
                className={`w-full px-4 py-2 rounded-md ${
                  darkMode ? 'bg-gray-700 text-white' : 'bg-white border-gray-300'
                }`}
                defaultValue="John Doe"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Email</label>
              <input
                type="email"
                className={`w-full px-4 py-2 rounded-md ${
                  darkMode ? 'bg-gray-700 text-white' : 'bg-white border-gray-300'
                }`}
                defaultValue="john@example.com"
              />
            </div>
          </div>
        </div>
        <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
          Save Changes
        </button>
      </div>
    </div>
  );
}

export default AccountSettings;
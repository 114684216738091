import React from 'react';
import { useTheme } from '../context/ThemeContext';

function MyCDN() {
  const { darkMode } = useTheme();

  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-md shadow-md`}>
      <h2 className="text-2xl font-semibold mb-4">My CDN</h2>
      <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>Manage your CDN settings here.</p>
      <div className="mt-6 space-y-4">
        <div className={`p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg`}>
          <h3 className="text-lg font-medium mb-2">CDN Status</h3>
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            <span>Active</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyCDN;
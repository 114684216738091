import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../context/ThemeContext';

const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const [supportDropdownOpen, setSupportDropdownOpen] = React.useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const { darkMode } = useTheme();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const sidebarBg = darkMode ? 'bg-gray-900' : 'bg-white';
  const textColor = darkMode ? 'text-gray-200' : 'text-gray-600';
  const hoverBg = darkMode ? 'hover:bg-gray-800' : 'hover:bg-gray-100';
  const activeBg = darkMode ? 'bg-gray-800' : 'bg-gray-100';
  const borderColor = darkMode ? 'border-gray-700' : 'border-gray-200';

  return (
    <div
      className={`fixed z-30 inset-y-0 left-0 w-64 transition duration-300 transform ${sidebarBg} border-r ${borderColor} overflow-y-auto lg:translate-x-0 lg:static lg:inset-0 flex flex-col ${
        sidebarOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
      }`}
    >
      <div className={`flex items-center justify-between px-4 py-3 ${sidebarBg} `}>
        <div className="flex items-center">
          <Link to="/" onClick={() => setSidebarOpen(false)}>
            <img
              src={darkMode ? "/logo-dark.svg" : "/logo-light.svg"}
              alt="Logo"
              className="h-14 w-auto"
            />
          </Link>
        </div>
        <button
          onClick={() => setSidebarOpen(false)}
          className={`${textColor} focus:outline-none focus:text-gray-500 lg:hidden`}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <nav className="mt-4 flex-1">
        <Link
          to="/"
          className={`flex items-center py-2 px-6 ${isActive('/') ? activeBg : ''} ${textColor} ${hoverBg}`}
          onClick={() => setSidebarOpen(false)}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
          </svg>
          <span className="mx-3">{t('Dashboard')}</span>
        </Link>

        <Link
          to="/mycdn"
          className={`flex items-center mt-4 py-2 px-6 ${isActive('/mycdn') ? activeBg : ''} ${textColor} ${hoverBg}`}
          onClick={() => setSidebarOpen(false)}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
          </svg>
          <span className="mx-3">{t('My CDN')}</span>
        </Link>

        <Link
          to="/analytics"
          className={`flex items-center mt-4 py-2 px-6 ${isActive('/analytics') ? activeBg : ''} ${textColor} ${hoverBg}`}
          onClick={() => setSidebarOpen(false)}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
          </svg>
          <span className="mx-3">{t('Analytics')}</span>
        </Link>

        <Link
          to="/billing"
          className={`flex items-center mt-4 py-2 px-6 ${isActive('/billing') ? activeBg : ''} ${textColor} ${hoverBg}`}
          onClick={() => setSidebarOpen(false)}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
          </svg>
          <span className="mx-3">{t('Billing')}</span>
        </Link>

        <div>
          <button
            onClick={() => setSupportDropdownOpen(!supportDropdownOpen)}
            className={`flex items-center justify-between w-full mt-4 py-2 px-6 ${
              isActive('/mytickets') || isActive('/openticket') ? activeBg : ''
            } ${textColor} ${hoverBg} focus:outline-none`}
          >
            <div className="flex items-center">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
              <span className="mx-3">{t('Support')}</span>
            </div>
            <svg
              className={`w-4 h-4 transform ${supportDropdownOpen ? 'rotate-180' : ''}`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          {supportDropdownOpen && (
            <div className="mt-2">
              <Link
                to="/mytickets"
                className={`flex items-center py-2 px-12 text-sm ${isActive('/mytickets') ? activeBg : ''} ${textColor} ${hoverBg}`}
                onClick={() => setSidebarOpen(false)}
              >
                <svg className="h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>
                <span>{t('My Tickets')}</span>
              </Link>
              <Link
                to="/openticket"
                className={`flex items-center py-2 px-12 text-sm ${isActive('/openticket') ? activeBg : ''} ${textColor} ${hoverBg}`}
                onClick={() => setSidebarOpen(false)}
              >
                <svg className="h-5 w-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>{t('Open Ticket')}</span>
              </Link>
            </div>
          )}
        </div>

        <Link
          to="/accountsettings"
          className={`flex items-center mt-4 py-2 px-6 ${isActive('/accountsettings') ? activeBg : ''} ${textColor} ${hoverBg}`}
          onClick={() => setSidebarOpen(false)}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <span className="mx-3">{t('Account Settings')}</span>
        </Link>

        <Link
          to="/apikey"
          className={`flex items-center mt-4 py-2 px-6 ${isActive('/apikey') ? activeBg : ''} ${textColor} ${hoverBg}`}
          onClick={() => setSidebarOpen(false)}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z" />
          </svg>
          <span className="mx-3">{t('API Key')}</span>
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
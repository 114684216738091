import React from 'react';
import { useTheme } from '../context/ThemeContext';

function ApiKey() {
  const { darkMode } = useTheme();
  
  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-md shadow-md`}>
      <h2 className="text-2xl font-semibold mb-4">API Key Management</h2>
      <p className="mb-4">Manage your API keys and access tokens here.</p>
      
      <div className="space-y-4">
        <div className={`p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg`}>
          <h3 className="text-lg font-medium mb-2">Current API Key</h3>
          <div className="flex items-center space-x-2">
            <input
              type="password"
              value="your-api-key-here"
              readOnly
              className={`flex-1 p-2 rounded ${darkMode ? 'bg-gray-600 text-white' : 'bg-white'}`}
            />
            <button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
              Copy
            </button>
          </div>
        </div>
        
        <div className="flex space-x-4">
          <button className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">
            Generate New Key
          </button>
          <button className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700">
            Revoke Key
          </button>
        </div>
      </div>
    </div>
  );
}

export default ApiKey;
import React from 'react';
import { useTheme } from '../context/ThemeContext';

function Dashboard() {
  const { darkMode } = useTheme();
  
  return (
    <main className={`flex-1 overflow-x-hidden overflow-y-auto ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-200'}`}>
      <div className="container mx-auto px-6 py-8">
        <h3 className={`text-3xl font-medium ${darkMode ? 'text-white' : 'text-gray-700'}`}>Dashboard</h3>
        <div className="mt-8">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {/* Stats Cards */}
            <div className={`p-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md`}>
              <h4 className="text-lg font-semibold mb-2">Total Bandwidth</h4>
              <p className="text-3xl font-bold">2.4 TB</p>
            </div>
            <div className={`p-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md`}>
              <h4 className="text-lg font-semibold mb-2">Active Users</h4>
              <p className="text-3xl font-bold">12,345</p>
            </div>
            <div className={`p-6 ${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-md`}>
              <h4 className="text-lg font-semibold mb-2">Response Time</h4>
              <p className="text-3xl font-bold">45ms</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Dashboard from './pages/Dashboard';
import MyCDN from './pages/MyCDN';
import Analytics from './pages/Analytics';
import Billing from './pages/Billing';
import MyTickets from './pages/MyTickets';
import OpenTicket from './pages/OpenTicket';
import AccountSettings from './pages/AccountSettings';
import ApiKey from './pages/ApiKey';
import { useTheme } from './context/ThemeContext';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { darkMode } = useTheme();

  return (
    <Router>
      <div className={`flex h-screen overflow-hidden ${darkMode ? 'dark bg-dark-primary' : 'bg-gray-100'}`}>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="flex-1 flex flex-col overflow-hidden">
          <Topbar setSidebarOpen={setSidebarOpen} />
          <main className={`flex-1 overflow-y-auto p-6 ${darkMode ? 'bg-dark-primary' : 'bg-gray-100'}`}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/mycdn" element={<MyCDN />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/mytickets" element={<MyTickets />} />
              <Route path="/openticket" element={<OpenTicket />} />
              <Route path="/accountsettings" element={<AccountSettings />} />
              <Route path="/apikey" element={<ApiKey />} />
            </Routes>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { useTheme } from '../context/ThemeContext';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { darkMode } = useTheme();

  const [selectedLang, setSelectedLang] = useState(Cookies.get('i18next') || 'en');

  useEffect(() => {
    i18n.changeLanguage(selectedLang);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedLang, i18n]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
    Cookies.set('i18next', lng, { expires: 365 });
    setDropdownOpen(false);
  };

  const flagIcons = {
    en: 'us',
    fr: 'fr',
    de: 'de',
    es: 'es',
    it: 'it',
    ar: 'sa',
    zh: 'cn',
    ja: 'jp',
    hi: 'in',
    bn: 'bd',
    ur: 'pk',
    pt: 'pt'
  };

  const languages = {
    en: 'English',
    fr: 'Français',
    de: 'Deutsch',
    es: 'Español',
    it: 'Italiano',
    ar: 'العربية',
    zh: '中文',
    ja: '日本語',
    hi: 'हिंदी',
    bn: 'বাংলা',
    ur: 'اردو',
    pt: 'Português'
  };

  const flagClass = `flag-icon flag-icon-${flagIcons[selectedLang]}`;

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className={`flex items-center px-2 py-2 rounded transition-colors ${
          darkMode
            ? 'hover:bg-gray-700 text-gray-200'
            : 'hover:bg-gray-100 text-gray-600'
        }`}
      >
        <span className={`${flagClass} !w-5 !h-4`}></span>
      </button>
      {dropdownOpen && (
        <div className={`absolute right-0 mt-1 w-40 rounded-md shadow-lg overflow-hidden z-20 ${
          darkMode ? 'bg-gray-800' : 'bg-white'
        }`}>
          {Object.entries(languages).map(([code, name]) => (
            <button
              key={code}
              onClick={() => changeLanguage(code)}
              className={`flex items-center w-full px-3 py-1.5 text-sm ${
                darkMode
                  ? 'text-gray-200 hover:bg-gray-700'
                  : 'text-gray-700 hover:bg-gray-100'
              } ${selectedLang === code ? (darkMode ? 'bg-gray-700' : 'bg-gray-100') : ''}`}
            >
              <span className={`flag-icon flag-icon-${flagIcons[code]} mr-2 !w-5 !h-4`}></span>
              {name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
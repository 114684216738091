import React from 'react';
import { useTheme } from '../context/ThemeContext';

function MyTickets() {
  const { darkMode } = useTheme();

  return (
    <div className={`p-6 ${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-md shadow-md`}>
      <h2 className="text-2xl font-semibold mb-4">My Tickets</h2>
      <p className={`${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>View and manage your support tickets here.</p>
      <div className="mt-6">
        <div className={`p-4 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'} rounded-lg`}>
          <h3 className="text-lg font-medium mb-2">Active Tickets</h3>
          <ul className="space-y-2">
            <li className="flex justify-between items-center">
              <span>Ticket #1234</span>
              <span className="px-2 py-1 bg-yellow-500 text-white rounded">In Progress</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MyTickets;
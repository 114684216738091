import React, { useState, useEffect, useRef } from 'react';
import { FiUser, FiKey, FiCreditCard, FiLogOut } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../context/ThemeContext';
import { Link } from 'react-router-dom';

const ProfileDropdown = () => {
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();
  const { darkMode } = useTheme();

  const toggleDropdown = () => setProfileDropdownOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setProfileDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={`flex items-center space-x-2 focus:outline-none ${
          darkMode ? 'text-gray-200' : 'text-gray-700'
        }`}
      >
        <span className="hidden md:block text-sm">{t('John Doe')}</span>
        <div className="relative block h-8 w-8 rounded-full overflow-hidden shadow">
          <img
            className="h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=296&q=80"
            alt="Your avatar"
          />
        </div>
      </button>

      {profileDropdownOpen && (
        <div className={`absolute right-0 mt-1 w-40 rounded-md shadow-lg overflow-hidden z-20 ${
          darkMode ? 'bg-gray-800' : 'bg-white'
        }`}>
          <Link 
            to="/accountsettings" 
            className={`flex items-center px-3 py-1.5 text-sm ${
              darkMode ? 'text-gray-200 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'
            }`}
            onClick={() => setProfileDropdownOpen(false)}
          >
            <FiUser className="mr-2 h-4 w-4" /> {t('Account Settings')}
          </Link>
          <Link 
            to="/apikey" 
            className={`flex items-center px-3 py-1.5 text-sm ${
              darkMode ? 'text-gray-200 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'
            }`}
            onClick={() => setProfileDropdownOpen(false)}
          >
            <FiKey className="mr-2 h-4 w-4" /> {t('API Key')}
          </Link>
          <Link 
            to="/billing" 
            className={`flex items-center px-3 py-1.5 text-sm ${
              darkMode ? 'text-gray-200 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'
            }`}
            onClick={() => setProfileDropdownOpen(false)}
          >
            <FiCreditCard className="mr-2 h-4 w-4" /> {t('Billing')}
          </Link>
          <Link 
            to="/logout" 
            className={`flex items-center px-3 py-1.5 text-sm ${
              darkMode ? 'text-gray-200 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'
            }`}
            onClick={() => setProfileDropdownOpen(false)}
          >
            <FiLogOut className="mr-2 h-4 w-4" /> {t('Logout')}
          </Link>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

const savedLanguage = Cookies.get('i18next') || 'en'; // Check saved language or default to 'en'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require('./locales/en.json') },
      fr: { translation: require('./locales/fr.json') },
      de: { translation: require('./locales/de.json') },
      es: { translation: require('./locales/es.json') },
      it: { translation: require('./locales/it.json') },
      ar: { translation: require('./locales/ar.json') },
      zh: { translation: require('./locales/zh.json') },
      ja: { translation: require('./locales/ja.json') },
      hi: { translation: require('./locales/hi.json') },
      bn: { translation: require('./locales/bn.json') },
      ur: { translation: require('./locales/ur.json') },
      pt: { translation: require('./locales/pt.json') }
    },
    fallbackLng: 'en',
    lng: savedLanguage, // Set the language on initialization based on the cookie
    detection: {
      order: ['cookie', 'navigator', 'localStorage'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  Cookies.set('i18next', lng, { expires: 365 });
});

export default i18n;
